import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, type OnInit } from '@angular/core';
import { MatButtonModule } from "@angular/material/button";
import { RouterModule } from "@angular/router";
import { SvgIconModule } from "../../icons/svg-icon/svg-icon.module";

@Component({
	selector: 'app-package-upgrade-btn',
	standalone: true,
	imports: [
		CommonModule,
		SvgIconModule, 
		MatButtonModule, 
		RouterModule
	],
	templateUrl: './package-upgrade-btn.component.html',
	styleUrls: [ './package-upgrade-btn.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PackageUpgradeBtnComponent implements OnInit {

	ngOnInit(): void { }

}
